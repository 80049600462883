import $ from 'jquery';
import * as geolib from 'geolib';
import loadGoogleMapsApi from 'load-google-maps-api';

import BaseController from "../base_controller"
import ManagedCollection from '../../js/util/managed_collection';

import CookieConsent from '../../js/util/cookie_consent';

import markerIcon from 'images/icons/ic-map-marker.svg';
import markerIconWithDistance from 'images/icons/ic-map-marker-with-distance.svg';
import userMarkerIcon from 'images/icons/ic-map-user-marker.svg';

let GoogleMapsApi = null;

export default class MapController extends BaseController {
    static DEFAULT_CENTER = { lat: 51.1642292 , lng: 10.4541194 };
    static DEFAULT_FULL_RADIUS = 1000;
    static DEFAULT_DETAIL_RADIUS = 50;

    connect() {
        let that = this;
        let google_maps_api_key = $('meta[name="google_maps_api_key"]').attr('content');

        this.$map = $(this.element);

        this.isCustomView = false;

        this.distanceReferenceLocation = null;

        this.userLocation = null;
        this.userLocationMarker = null;
        this.managedMarkers = new ManagedCollection();

        this.googleMapsApiPromise = new Promise((resolve ,reject) => {
            CookieConsent.onConsent().then(() => {
                loadGoogleMapsApi({ key: google_maps_api_key }).then(resolve, reject);
            })
        }).then((api) => {
            GoogleMapsApi = api;
            that.setupMap();
        });
    }

    waitForGoogleMaps() {
        return this.googleMapsApiPromise;
    }

    setupMap() {
        let that = this;

        this.googleMap = new GoogleMapsApi.Map(this.$map[0], {
            center: MapController.DEFAULT_CENTER,
            zoom: 6,
            minZoom: 6,
            maxZoom: 17
        });

        this.resetView();

        this.googleMap.addListener('zoom_changed', () => {
            if(that.suppressInteractionEvents) return;

            that.isCustomView = true;
            that.trigger('map:radius_changed', that.radius);
        });

        this.googleMap.addListener('center_changed', () => {
            if(that.suppressInteractionEvents) return;

            let latitude = this.googleMap.getCenter().lat();
            let longitude = this.googleMap.getCenter().lng();

            that.isCustomView = true;
            that.showDistanceToMapCenter = false;
            that.updateMarkers();

            that.trigger('map:center_changed', latitude, longitude);
        });

        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                that.setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            });
        }
    }

    get location() {
        return {
            latitude: this.googleMap.getCenter().lat(),
            longitude: this.googleMap.getCenter().lng()
        }
    }

    set location(location) {
        let mapsLatLng;

        if(location.latitude && location.longitude) {
            mapsLatLng = new GoogleMapsApi.LatLng(location.latitude, location.longitude);
        } else if(this.userLocation) {
            mapsLatLng = new GoogleMapsApi.LatLng(this.userLocation.latitude, this.userLocation.longitude);
        } else {
            mapsLatLng = new GoogleMapsApi.LatLng(
                MapController.DEFAULT_CENTER.lat,
                MapController.DEFAULT_CENTER.lng
            );
        }

        this.googleMap.setCenter(mapsLatLng)
    }

    get radius() {
        // calculation based on https://groups.google.com/forum/#!msg/google-maps-js-api-v3/hDRO4oHVSeM/osOYQYXg2oUJ
        let zoom = this.googleMap.getZoom();
        let latitude = this.googleMap.getCenter().lat();

        return 156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom);
    }

    set radius(radius) {
        // calculation based on https://groups.google.com/forum/#!msg/google-maps-js-api-v3/hDRO4oHVSeM/osOYQYXg2oUJ
        let latitude = this.googleMap.getCenter().lat();
        let zoom = Math.log2(156543.03392 * Math.cos(latitude * Math.PI / 180) / (radius || MapController.DEFAULT_FULL_RADIUS));

        this.googleMap.setZoom(zoom);
    }

    get referenceLocation() {
        return this.userLocation || this.distanceReferenceLocation;
    }

    setView(location, radius, distanceReferenceLocation) {
        this.suppressInteractionEvents = true;

        this.location = location;
        this.radius = radius;
        this.distanceReferenceLocation = distanceReferenceLocation;

        this.updateMarkers();

        this.suppressInteractionEvents = false;
    }

    resetView() {
        this.isCustomView = false;

        if(this.userLocation) {
            this.setView(this.userLocation, MapController.DEFAULT_DETAIL_RADIUS);
        } else {
            this.setView(MapController.DEFAULT_CENTER, MapController.DEFAULT_FULL_RADIUS);
        }
    }

    setUserLocation(location) {
        this.userLocation = location;

        let position = new GoogleMapsApi.LatLng(this.userLocation.latitude, this.userLocation.longitude);

        if(this.userLocationMarker) {
            this.userLocationMarker.setPosition(position);
        } else {
            this.userLocationMarker = new GoogleMapsApi.Marker({
                position: position,
                clickable: false,
                zIndex: 999,
                shadow: null,
                icon: new GoogleMapsApi.MarkerImage(
                    userMarkerIcon,
                    new GoogleMapsApi.Size(30,30),
                    new GoogleMapsApi.Point(0,0),
                    new GoogleMapsApi.Point(15,15)
                )
            });

            this.userLocationMarker.setMap(this.googleMap);
        }

        if(!this.isCustomView) {
            this.resetView();
        }
    }

    setMarkers(markers) {
        if(!this.googleMap) return false;
        let that = this;

        let mapMarkers = markers
            .filter((marker) => {
                if(!marker.position) return false;
                if(marker.position.latitude !== 0.0) return true;
                if(marker.position.longitude !== 0.0) return true;

                return false;
            })
            .map((marker) => {
                return new MapMarker(marker);
            });

        let changes = this.managedMarkers.setCollection(mapMarkers);

        changes.additions.forEach((marker) => {
            marker.addToMap(that.googleMap);
            marker.showDistanceTo(that.referenceLocation);
        });

        changes.deletions.forEach((marker) => {
            marker.removeFromMap();
        });

        return this.managedMarkers.collection;
    }

    updateMarkers() {
        let that = this;

        this.managedMarkers.forEach((marker) => {
            marker.showDistanceTo(that.referenceLocation);
        })
    }
}

class MapMarker {
    constructor(params, markerParams) {
        this.defaultIcon = {
            url: markerIcon,
            size: new GoogleMapsApi.Size(44, 91),
            origin: new GoogleMapsApi.Point(0, -25),
            anchor: new GoogleMapsApi.Point(22, 91)
        };

        this.distanceIcon = {
            url: markerIconWithDistance,
            size: new GoogleMapsApi.Size(44, 91),
            origin: new GoogleMapsApi.Point(0, -25),
            anchor: new GoogleMapsApi.Point(22, 91)
        };

        let data = $.extend({
            position: new GoogleMapsApi.LatLng(params.position.latitude, params.position.longitude),
            title: params.title,
            icon: this.defaultIcon
        }, markerParams);

        this.id = params.id;
        this.marker = new GoogleMapsApi.Marker(data);
        this.isOnline = params.isOnline;

        this.marker.addListener('click', () => {
            if(params.link) {
                let win = window.open(params.link, '_blank');
                win.focus();
            }
        });

        if(this.isOnline) {
            this.setLabel('eCME', 12);
        }

        this.distanceReferenceLocation = null;
    }

    get position() {
        if(!this.marker.position) return;

        return {
            latitude: this.marker.position.lat(),
            longitude: this.marker.position.lng()
        }
    }

    addToMap(map) {
        this.marker.setMap(map);
    }

    removeFromMap() {
        this.marker.setMap(null);
    }

    clearDistance() {
        if(this.isOnline) return;

        this.distanceReferenceLocation = null;
        this.setDistanceLabel(null);
        this.marker.setIcon(this.defaultIcon);
    }

    showDistanceTo(otherPosition) {
        if(this.isOnline) return;
        if(!otherPosition) return this.clearDistance();

        if(
            this.distanceReferenceLocation &&
            this.distanceReferenceLocation.latitude === otherPosition.latitude &&
            this.distanceReferenceLocation.longitude === otherPosition.longitude
        ) {
            return;
        }

        this.distanceReferenceLocation = otherPosition;

        let meters = geolib.getDistance(this.position, otherPosition);
        let roundedKilometers = Math.ceil(meters / 1000);

        let label;

        if(roundedKilometers <= 20) {
            label = roundedKilometers;
        } else if(roundedKilometers > 20 && roundedKilometers <= 100) {
            label = Math.ceil(roundedKilometers / 5) * 5;
        } else if(roundedKilometers > 100 && roundedKilometers <= 200) {
            label = Math.ceil(roundedKilometers / 25) * 25;
        } else if(roundedKilometers > 200 && roundedKilometers <= 500) {
            label = Math.ceil(roundedKilometers / 50) * 50;
        } else {
            label = ">500"
        }

        this.setDistanceLabel(label);
        this.marker.setIcon(this.distanceIcon);
    }

    setDistanceLabel(value) {
        if(this.isOnline) return;
        if(!value) return this.marker.setLabel(null);

        let size = 18;
        let label = value.toString();

        if(label.length > 3) {
            size = 14;
        }

        this.setLabel(label, size);
    }

    setLabel(label, size) {
        this.marker.setLabel({
            text: label,
            color: 'white',
            fontFamily: 'SFProText',
            fontSize: size +'px',
            fontWeight: '600'
        });
    }
}
